<template>
  <b-modal
    id="cms-manage-floating-ads"
    title="Manage floating ads"
    no-close-on-backdrop
    @ok="onOk"
  >
    <validation-observer
      ref="cmsAdsForm"
    >
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="image_src"
          rules="required"
        >
          <b-form-group label="Image">
            <CmsFileSelect
              :value="cmsFloatingAdsForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (cmsFloatingAdsForm.imageSrc = value || '')"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="display_condition"
          rules="required"
        >
          <b-form-group label="Floating position">
            <b-select
              v-model="cmsFloatingAdsForm.floatingPosition"
              :state="errors[0] ? false : null"
            >
              <b-select-option
                v-for="(item, index) of positions"
                :key="index"
                :value="item"
              >
                {{ item }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="display_condition"
          rules="required"
        >
          <b-form-group label="External link?">
            <b-form-checkbox
              v-model="cmsFloatingAdsForm.isExternalLink"
              switch
              :state="errors[0] ? false : null"
              size="lg"
            />
          </b-form-group>
        </validation-provider>
        <b-form-group
          v-if="cmsFloatingAdsForm.isExternalLink"
          label="External link target"
        >
          <b-form-input
            v-model="cmsFloatingAdsForm.externalLinkTo"
            placeholder="https://google.com"
          />
        </b-form-group>
        <b-form-group
          v-else
          label="Internal link target"
        >
          <b-form-input
            v-model="cmsFloatingAdsForm.internalLinkTo"
            placeholder="/dashboard"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from '@validations';

export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
  },
  data() {
    return {
      required,

      cmsFloatingAdsForm: {
        imageSrc: '',
        floatingPosition: 'BOTTOM_RIGHT',
        isExternalLink: false,
        internalLinkTo: '',
        externalLinkTo: '',
      },
      positions: [
        'TOP_LEFT',
        'TOP_RIGHT',
        'MIDDLE_LEFT',
        'MIDDLE_RIGHT',
        'BOTTOM_LEFT',
        'BOTTOM_RIGHT',
        'MIDDLE',
      ],
    }
  },
  computed: {
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsFloatingAds']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-manage-floating-ads'))
      }
    },
    async onSubmit(cb) {
      this.$refs.cmsAdsForm.validate().then( async (success) => {
        if (success) {
          await this.createCmsFloatingAds({
            siteId: this.siteId,
            floatingAds: [this.cmsFloatingAdsForm],
          })

          cb()
        }
      })
      
    },
  },
}
</script>
